import React from 'react'
import Address from '../components/Address'
import LongId from '../components/LongId'
import Network from '../components/Network'
import Token from '../components/Token'
import TokenAmount from '../components/TokenAmount'
import Table from '../structure/Table/Table'
import TableRow from '../structure/Table/TableRow'
import TableHeader from '../structure/Table/TableHeader'
import TableCell from '../structure/Table/TableCell'
import { capitalizeFirstLetter } from '../utils/general-utils'
import { formatTokenAmount } from '../utils/math-utils'
import styled from 'styled-components'

const ParamTable = ({ data }) => {

  return (
    <CustomTable
      header={
        <TableRow>
          {Object.keys(data[0]).map(
            item =>
              data[0][item] && (
                <TableHeader
                  key={item}
                  title={capitalizeFirstLetter(
                    item.replace(/([A-Z]+)*([A-Z][a-z])/g, '$1 $2')
                  )}
                  align="left"
                />
              )
          )}
        </TableRow>
      }
    >
      {data.map((value, i) => (
        <TableRow key={i}>
          {Object.entries(value).map(
            ([key, item]) =>
              item && (
                <TableCell key={key}>
                  <Item data={item.toString()} item={item} type={key} value={value} />
                </TableCell>
              )
          )}
        </TableRow>
      ))}
    </CustomTable>
  )
}
const CustomTable = styled(Table)`
  th {
    white-space: normal;
    vertical-align: top;
    display: table-cell;
  }
`

const Item = ({ data, type = 'string', value = '', item }) => {
  if(type === 'volumeLimit') {
    return (
      <span style={{display: 'flex', gap: '7px'}}>
        {formatTokenAmount(item?.amount, item?.token?.decimals, {
          digits: 4,
        }).toLocaleString()}
        <Token address={item?.id} item={item?.token} open={true}/> every <Period seconds={item?.period}/>
      </span>
    )
  }

  if (type === 'address' || type === 'signer' || type === 'recipient' || type === 'connector' ) {
    return <Address address={data} chainId={value?.network} />
  }
  if (type === 'feeCollector') {
    return <Address address={data} short={true} chainId={value?.network} />
  }
  if (type === 'network') {
    return <Network network={data} />
  }
  if (type === 'token') {
    return <Token address={data} open={true} />
  }
  if (type === 'tokenOut') {
    return <Token address={item.id} item={item} open={true}/>
  }
  if (type === 'minThreshold' || type === 'maxThreshold' ) {
    return <TokenAmount tokenAmount={item} />
  }
  if (type === 'nextBalanceConnector' || type === 'previousBalanceConnector') {
    return <LongId longId={data} />
  }
  return <>{data}</>
}

const Period = ({ seconds }) => {
  if(seconds > 3600 && seconds < 86400) {
    const hours = seconds / 3600
    const formattedHours = hours % 1 !== 0 ? hours.toFixed(2) : Math.floor(hours)
    return <span>{formattedHours} hours</span>
  }
  if(seconds === 86400) {
    return <span>day</span>
  }
  if(seconds > 86400) {
    const days = seconds / 86400
    const formattedDays = days % 1 !== 0 ? days.toFixed(2) : Math.floor(days)
    return <span>{formattedDays} days</span>
  }


  return <>{seconds} seconds</>
}


export default ParamTable
