import React, { useState } from 'react'
import AccordionItem from '../sections/AccordionItem'
import ParamTable from '../sections/ParamTable'
import Permissions from './Permissions'
import { Heading1, Heading2, StandardText } from '../styles/texts'
import { Skeleton } from '../styles/general'
import { ConfigContainer } from '../styles/general'
import { parseActionName } from '../utils/general-utils'

const TasksParams = ({ width, task }) => {
  const [active, setActive] = useState('Address')
  const handleToggle = index => {
    if (active === index) {
      setActive(null)
    } else {
      setActive(index)
    }
  }

  return (
    <>
      <ConfigContainer style={{ paddingTop: '0' }}>
        <Heading1>
          {task ? (
            parseActionName(task?.name) + ' Params'
          ) : (
            <Skeleton height="24px" width="100%" />
          )}{' '}
        </Heading1>
        { !task ? (
          <Skeleton height="24px" width="100%" />
        ) : task.taskConfig ? (
          <>
            {Object.keys(task.taskConfig).map(
              key =>
                task.taskConfig[key] &&
                task.taskConfig[key].length > 0 && key !== 'Custom' && (
                  <AccordionItem
                    key={key}
                    active={active}
                    handleToggle={handleToggle}
                    id={key}
                    header={<Heading2>{key}</Heading2>}
                    content={<ParamTable data={task.taskConfig[key]} />}
                  />
                )
            )}

          </>
        ) : (
          <StandardText>No params found</StandardText>
        )}
      </ConfigContainer>
      {task.taskConfig && task.taskConfig.Custom && task.taskConfig.Custom.length > 0 &&
        <ConfigContainer>
          <Heading1>
            Custom params
          </Heading1>
          {Object.keys(task.taskConfig.Custom[0]).map(
            key =>
              <AccordionItem
                key={key}
                active={active}
                handleToggle={handleToggle}
                id={key}
                header={<Heading2>{key}</Heading2>}
                content={<ParamTable data={[task.taskConfig.Custom[0][key]]}/>}
              />
          )}
        </ConfigContainer>
      }
      <Permissions action={task} />
    </>
  )
}

export default TasksParams
